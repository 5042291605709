import React, { useState } from 'react';
import Box from './box';
import '../terminal.css';
import '../fonts/Terminal.woff';


const Button = ({text, height, width, corner, onClick, fixHeight}) => {

  const [hover, setHover] = useState(false);



	return <Box fixHeight={fixHeight} height={height} width={width} padding={8} corner={corner} opacity={hover?0.7:0.4}>
            <button
                onClick={onClick}
                style={{height:height-3, width:width-16, background:"transparent", border:"none"}}
                onMouseEnter={() => setHover(true)}
                onMouseLeave={() => setHover(false)}>
                <h1 style={{color:"white", margin:0, fontFamily: "Terminal", opacity:0.8, letterSpacing: "4px"}}>{text}</h1>
            </button>
          </Box>
}

export default Button