
import React from 'react'
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';

const carouselSettings={
	autoPlay:true,
	interval:5000,
	dynamicHeight:false,
	infiniteLoop:true,
	showThumbs:false,
	showStatus:false
}

const Car = ({width, srcs, imgHeight}) => {
	return <div style={{overflow:"hidden", width:width, align:"center"}}>
		<Carousel {...carouselSettings}>
		{
			srcs.map( (src) =>{
				return <div>
					<img src={src} style={{width: "auto", height:  imgHeight || 375}}/>
				</div>
			})
		}
		</Carousel>
	</div>
}

export default Car