import React from 'react'
import blueMovement from '../Assets/bluemovement.png'
import innerMovementc from '../Assets/innermovementc.png'
import './logo.css';

const center = {
	position: "absolute",top: "-60px", left: 0, right: 0, bottom: 0, justifyContent: 'center', alignItems: 'center'
}

const kmStyle = {
	fontWeight:"bold",
	fontStyle:"italic",
	fontSize:82,
	color:"red",
    whiteSpace: "nowrap"
}

const Logo = props => {
	return <div style={{margin:"20px", ...center}}>
		<div>
			<h1 style={kmStyle} className={"strokeme"}>KILLING MACHINE</h1>
		</div>
		<div style={{...center, top:150}}>
			<img src={blueMovement} alt={"blueMovement"} alt={"Movement"}/>
		</div>
		<div style={{...center, top:150}} className={"animate-flicker"}>
			<img src={innerMovementc} alt={"Movement"}/>
		</div>
	</div>
}

export default Logo