import React from 'react'

const lineWeight = 4

const Box = ({height, width, corner, style, children, opacity, padding, down, fill, fixHeight}) => {

	const cornerSize = (corner === undefined) ? 30 : corner
	const fillOpacity = opacity || 0.3
	const pad = (padding === undefined) ? 30 : padding

	return <div style={{...style, width:width, height:height}}>
		<svg
			style={fixHeight ? {height:height + 8} : {}}
		    viewBox={`-${lineWeight*2} -${lineWeight*2} ${width+lineWeight*4} ${height+lineWeight*4}`}
		    xmlns={"<http://www.w3.org/2000/svg>"}
			>
			<polygon 
       			points={`-${lineWeight},-${lineWeight} ${width+lineWeight},-${lineWeight} ${width+lineWeight},${height-cornerSize+lineWeight} ${width-cornerSize+lineWeight},${height+lineWeight} -${lineWeight},${height+lineWeight}`}
       			stroke="black"
       			fill="none"
       			strokeOpacity={fillOpacity}
       			strokeWidth={lineWeight}
       			strokeLinecap="square"
       		/>
       		<polygon 
       			points={`0,0 ${width},0 ${width},${height-cornerSize} ${width-cornerSize},${height} 0,${height}`}
       			stroke="white"
       			fill={fill || "black"}
       			fillOpacity={fillOpacity}
       			strokeOpacity={fillOpacity+0.2}
       			strokeWidth={lineWeight}
       			strokeLinecap="square"
       		/>
	    </svg>
	    <div style={{position:"absolute", top:pad + (down||0), left:pad, width: width-pad*2, height:height}}>
	    	{children}
	    </div>
	</div>
}

export default Box