import React from 'react'
import './background.css';

const smallestPixel = 8
const blockSize = 200
const refresh = 110
const slowAfter = 60000
const slowRefresh = 2000

function drawStatic(context, canvasHeight, canvasWidth) {
    for (var v=0; v < canvasHeight; v += smallestPixel){
        for (var h=0; h < canvasWidth; h += smallestPixel){
            var lum = Math.floor( Math.random() * 20 );
            // var sat = 70+Math.floor( Math.random() * 30 );
            // context.fillStyle = "hsl(0, "+sat+"%," + lum + "%)";
            context.fillStyle = "hsl(0, 100%," + lum + "%)";
            context.fillRect(h,v, smallestPixel, smallestPixel);
      }
   }
}

const TileCanvas = ({screenX, screenY}) => {

	console.log(screenY, screenY)

	const singleTile = React.useRef();
	const canv = React.useRef();


	const draw = () => {

		const singleTileCtx = singleTile.current.getContext('2d');
    drawStatic(singleTileCtx, blockSize, blockSize);
  	const ctx = canv.current.getContext('2d');
  	const pat = ctx.createPattern(singleTile.current, "repeat")
  	pat.setTransform(new DOMMatrix([1,0,0,1,screenX, screenY]))
		ctx.fillStyle = pat;
		ctx.fillRect(0, 0, screenX, screenY);
	}

	React.useEffect(() =>{
		draw()
		const timerId = setInterval(draw,refresh)
		setTimeout(() => {clearInterval(timerId); setInterval(draw,slowRefresh)}, slowAfter)
	});

	
	return (
		<>
			<canvas ref={singleTile} height={blockSize} width={blockSize} style={{display: 'none'}} />
    	<canvas ref={canv} width={screenX} height={screenY} style={{width:screenX, height:screenY}} />
    </>
  )
}

const Background = props => {
	return <div>
		<div className={"bg"} />
		<div className={"static"}>
			<TileCanvas {...props}/>
		</div>
	</div>
}

export default Background

