import React from 'react'

import './terminal.css';
import './fonts/Terminal.woff';
import Box from './Components/box.jsx';
import download from './Assets/download.svg'
import { useEffect } from 'react';
import YoutubeEmbed from './Components/youtube.jsx';
import Car from './Components/carousel.jsx';

const History = ({mainBoxHeight, mainBoxWidth}) => {

	useEffect(() => {
		document.title = 'Killing Machine Movement - History';
	}, []);

	const textStyle = {color:"white", margin:0, marginBottom:"15px", fontFamily: "Terminal", fontSize:20, letterSpacing:"3px", opacity:0.9, float:"left", textAlign:"left"}

	return <Box height={mainBoxHeight-160} width={mainBoxWidth-60} down={100} style={{paddingTop:20}}>
		<div style={{...textStyle, overflowY:"scroll", height:mainBoxHeight-220}}>
			<h2>A long time coming.</h2>
			<p>As kids we had made some games. Including the original <a href="games/killmac.zip" download>KILLING MACHINE</a> as well as <a href="games/sneezer.zip" download>Sneezer</a>, <a href="games/km.zip" download>Karnage Man</a>, <a href="games/ras.zip" download> Karnage Man 2: Run Around Shooting</a> and many more under the banner of <a href="shs/">Shrivelled Heart Software</a>. As teenagers our games became more complex, and we finished games such as <a href="games/trolley2.zip" download>Trolley 2</a>, <a href="games/RTsetup.zip" download>Rocketron</a> and <a href="games/Op420v101.rar" download>Operation 420</a>. This game is full of references to these old games.</p>
			<Car width={mainBoxWidth-200} srcs={["images/km1.png", "images/rocketron4.png", "images/trolley.png"]}/>

			<p>In 2006, work on the sequel to Rocketron began. This original game was supposed to be an incredibly ambitious top down RPG with side scrolling sections (and stock market trading). However when it came to it, the side scrolling engine just felt like a separate game.</p>

			
			<h2>Movement</h2>
			<p><strong>KILLING MACHINE: Movement</strong> began its life as this engine. Starting with some original Rocketron 2 ideas, we got a bit carried away, adding more guns, and spells, and augments, and kung fu, etc.</p>
			
			<YoutubeEmbed embedId="sRZzYVqxkVQ" height={560} autoplay={true}/>

			<h2>Story</h2>
			<p>This game needed a story, so in 2007 we commissioned one from the creator of other ambitious projects such as the graphic novel <strong>O + Xs Flosses</strong>.
			 It took four hours to write the initial script, but even at that time it was clear that its four acts equalled the ambition of the project.</p>
			
			<Car width={mainBoxWidth-200} imgHeight={500} srcs={[
				"images/MADsmall.png", 
				"images/KarnageMan.jpg",
				"images/HexVibratoHawaiianBabyWoodroseGoodlipsSlimedadAbiMountain.jpg",
				...([1,2,3,4,5,6,7,8,9,10,11].map(n => `images/Scan${n}.JPG`)), 
				"images/years.png",
				"images/Keliss.jpg",
				"images/MalachaiClayEvansKadorKeliss.jpg",
				"images/SlivekKadorPerr.jpg",
				]}/>

			<p> We even recorded an early version of one of the cutscenes which you can see here:</p>

			<YoutubeEmbed embedId="r9EahpixUZM" height={420} autoplay={false}/>
			 <p>The story became a prequel to the original <strong>KILLING MACHINE</strong>. But as such an overly ambitious and huge project, it took a lot of work. The years went on and this work became sporadic.</p>
		
			<Car width={mainBoxWidth-200} srcs={["images/cs9.png", "images/cs2.png", "images/cs3.png", "images/cs4.png", "images/cs5.png", "images/cs6.png", "images/cs7.png", "images/cs8.png", "images/cs1.png"]}/>

			<p>We began to collect voice actors and create the many cutscenes in the game to bring this story to life. Friends were recorded over the years, totalling 59 voice actors in the game as it stands.</p>

			<YoutubeEmbed embedId="gAFbp2BJUcc" height={320} autoplay={false}/>
			

			<h2>A halt to production</h2>
			<p>Sadly <strong>Marcus Cass</strong> died at the age of 27 during this time. He was one of the main developers, the creator of the original <strong>KILLING MACHINE</strong>, the person who would have played this game the most, as well as the originator of the <strong>Shrivelled Heart Software</strong> brand. Development slowed to a halt after this. This game is dedicated to him.</p>
			
			<h2>Final push</h2>
			<p>A few years later in 2021, we dusted this project off. So much work had been sunk into it already. We decided one way or another we would get it completed. Scoped back to two acts from the original four, it was possible. The end was in sight. We said give it one year and release whatever state it is in.</p>
			<p>Two years later, it is 2023, we are overdue on every deadline, <a href="/download">but it is finally here!</a></p>

			<Car width={mainBoxWidth-200} imgHeight={420} srcs={[
				"images/abi.png",
				"images/dogfight.png",
				"images/map.png",
				"images/mech.png",
				"images/rain.png",
				"images/canyon.PNG",
				"images/defend.PNG",
				"images/defend2.PNG",
				"images/dogfight2.PNG",
				"images/dogfight3.PNG",
				"images/factoryline.PNG",
				"images/green.PNG",
				"images/jungle.PNG",
				"images/madandmat.PNG",
				"images/psy.PNG",
				"images/quad.PNG",
				"images/rsm1.PNG",
				"images/rsm2.PNG",
				"images/rsm3.PNG",
				"images/sludge1.PNG",
				"images/sludge2.PNG",
				"images/transport.PNG"
			]}/>

			<p>If you want more acts let us know in the <a href="/chat">chat</a>.</p>

		</div>
	  </Box>

}

export default History