import React from 'react'

import './terminal.css';
import './fonts/Terminal.woff';
import Box from './Components/box.jsx';
import download from './Assets/download.svg'
import { useState, useEffect } from 'react';

const Download = ({mainBoxHeight, mainBoxWidth}) => {

	const textStyle = {color:"white", margin:0, marginBottom:"15px", fontFamily: "Terminal", fontSize:40, letterSpacing:"3px", opacity:0.9, textAlign:"center"}

	useEffect(() => {
		document.title = 'Killing Machine Movement - Download';
	}, []);

	const [isHover, setHover] = useState(false)
	const [isHover2, setHover2] = useState(false)

	return <Box height={mainBoxHeight-160} width={mainBoxWidth-60} down={100} style={{paddingTop:20}}>
		<a href={"/games/MovementInstaller.exe"} download>
		    <div onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
			    <Box opacity={isHover ? 0.6 : 0.3} fill="red" height={(mainBoxHeight-160-80)/2} width={mainBoxWidth-60-70} down={(mainBoxHeight-160-80)/4-200} style={{paddingTop:10}} corner={0}>
			      <div style={{color:"white", margin:0, marginBottom:"15px", fontFamily: "Terminal", fontSize:80, letterSpacing:"3px", opacity:0.9}}>
			        <p>DOWNLOAD<img src={download} alt={"download"} style={{paddingLeft: "40px"}}/></p>
			        <p style={{fontSize:20}}>LATEST VERSION v1.0.0</p>
			        <p style={{fontSize:20}}>Installer exe (recommended)</p>
			      </div>
			    </Box>
		    </div>
	    </a>
	    <a href={"/games/Movement.zip"} download>
		    <div onMouseEnter={() => setHover2(true)} onMouseLeave={() => setHover2(false)}>
			    <Box opacity={isHover2 ? 0.6 : 0.3} fill="red" height={(mainBoxHeight-160-80)/2} width={mainBoxWidth-60-70} down={(mainBoxHeight-160-80)/2+(mainBoxHeight-160-80)/4-200} style={{paddingTop:10}}>
			      <div style={{color:"white", margin:0, marginBottom:"15px", fontFamily: "Terminal", fontSize:80, letterSpacing:"3px", opacity:0.9}}>
			        <p>DOWNLOAD<img src={download} alt={"download"} style={{paddingLeft: "40px"}}/></p>
			        <p style={{fontSize:20}}>LATEST VERSION v1.0.0</p>
			        <p style={{fontSize:20}}>Zip Archive (portable)</p>
			      </div>
			    </Box>
		    </div>
	    </a>
	    	   {/* <a href={"/games/Movement.zip"} download>
		    <div onMouseEnter={() => setHover2(true)} onMouseLeave={() => setHover2(false)}>
			    <Box opacity={isHover2 ? 0.6 : 0.3} fill="red" height={(mainBoxHeight-160-80)} width={mainBoxWidth-60-70} down={(mainBoxHeight-160-80)/2-200} style={{paddingTop:10}}>
			      <div style={{color:"white", margin:0, marginBottom:"15px", fontFamily: "Terminal", fontSize:80, letterSpacing:"3px", opacity:0.9}}>
			        <p>DOWNLOAD<img src={download} alt={"download"} style={{paddingLeft: "40px"}}/></p>
			        <p style={{fontSize:20}}>LATEST VERSION v1.0.0</p>
			        <p style={{fontSize:20}}>Zip Archive</p>
			      </div>
			    </Box>
		    </div>
	    </a>*/}
	  </Box>

}

export default Download