import React from 'react'
import Box from './box';
import Button from './button';
import { useNavigate } from 'react-router-dom';

const ButtonBar = ({width, height, buttons}) => {

	const navigate = useNavigate();

	const padding = 8
	const numButtons = buttons.length
	const buttonHeight = height - padding*2
	const buttonWidth = (width-padding*2)/numButtons

	let totalFixedLength = 0
	let numNonFixed = numButtons

	buttons.forEach(({text, minWidth}) => {
		if (minWidth && (minWidth > buttonWidth)) {
			console.log(text)
			totalFixedLength += minWidth
			numNonFixed -= 1
		}
	})

	console.log("buttonWidth", buttonWidth)

	console.log("totalFixedLength", totalFixedLength)
	console.log("numNonFixed", numNonFixed)

	const flexButtonWidth = numNonFixed ? (width-padding*2 - totalFixedLength)/numNonFixed : buttonWidth

	console.log("flexButtonWidth", flexButtonWidth)

	let p = 0

	const butans = buttons.map( ({text, href, minWidth}, i) => {
	            		const notLast = i < numButtons - 1 
	            		const corner = notLast ? 0 : 26
	            		const fudgeWidth = notLast ? 6 : 0

	            		const w = minWidth ? Math.max(minWidth, flexButtonWidth) : flexButtonWidth
	            		

	            		const l = p
	            		p+=w
	            		console.log(text, w, minWidth, l, p)

	            		return <div key={i} style={{position:"absolute", left:l}}>
	            			<Button text={text} fixHeight={totalFixedLength > 0} height={buttonHeight+2} width={w+fudgeWidth} corner={corner} onClick={()=>navigate(href)}/>
            			</div>
	            	})


	return <Box height={height} width={width} padding={padding}>
			<div>
	            {butans}
            </div>
            <div style={{height:0, width:0, overflow:"hidden"}}>
            	{
            		buttons.map(({text, href}, i) => {
            			return <a href={href}>{text}</a>
            		})
            	}
            </div>
          </Box>
}

export default ButtonBar