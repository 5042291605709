import React from 'react'

import './terminal.css';
import './fonts/Terminal.woff';
import YoutubeEmbed from './Components/youtube.jsx';
import Box from './Components/box.jsx';
import { useEffect } from 'react';

const textStyle = {color:"white", margin:0, marginBottom:"15px", fontFamily: "Terminal", fontSize:30, letterSpacing:"3px", opacity:0.9, float:"left", textAlign:"left"}
const smallTextStyle = {...textStyle, fontSize:20}
const smallerTextStyle = {...textStyle, fontSize:10}

const About = ({mainBoxHeight, mainBoxWidth}) => {

	useEffect(() => {
		document.title = 'Killing Machine Movement - About';
	}, []);

	const youtubeHeight = Math.min(560, (mainBoxHeight-160)/2)

	return <>
		<Box height={youtubeHeight} width={mainBoxWidth-60} down={100} style={{paddingTop:20}} corner={0}>            
			<YoutubeEmbed embedId="RyH_qR22LZU" height={youtubeHeight} autoplay={true}/>
		</Box>
		<Box height={mainBoxHeight-180-youtubeHeight} width={mainBoxWidth-60} down={youtubeHeight+100} style={{paddingTop:20}}>
			<div style={{overflowY:"scroll", height:mainBoxHeight-220-youtubeHeight}}>            
				<p style={textStyle}>
					<h4><strong>KILLING MACHINE: Movement</strong> is an overly ambitious action platformer.</h4>
				</p>
				<p style={smallTextStyle}>
		 			You are <strong>Kador</strong>, a heavily armed nano-augmented spell casting former agent of the Corset Timarchy. You navigate a psypunk future society of shifting alliances. Starting in the continent of <strong>Mass City</strong>.
				</p>
				<p style={smallTextStyle}>
					Featuring:
				</p>

				<div class="row" style={{...smallTextStyle, margin:"auto"}}>
					<div class="column">
						<ul>
							<li>31 Weapons</li>
							<li>13 Spells</li>
							<li>15 Augments</li>
							<li>8 Types of grenade</li>
							<li>Complex and compelling story</li>
							<li>Many cutscenes</li>
							<li>Philosophical conversations</li>
							<li>59 Voice actors</li>
							<li>Complex hacking terminals</li>
						</ul>
					</div>
					<div class="column">
						<ul>
							<li>54 Levels</li>
							<li>Spread across two acts</li>
							<li>Kung-fu combo system</li>
							<li>Custom music</li>
							<li>8 Game modes</li>
							<li>Secrets you'll never find</li>
							<li>In game chat</li>
							<li>15 years of feature creep</li>
						</ul>
					</div>
				</div>
			</div>
			<div class="row" style={{...smallerTextStyle, margin:"auto", width:"100%"}}>
				<div class="column" style={{width:"20%"}}><a href="https://create-games.com/download.asp?id=9423">Daily Click</a></div>
				<div class="column" style={{width:"20%"}}><a href="https://kadormazing.itch.io/movement">itch.io</a></div>
			</div>

		</Box>
		
	</>

}

export default About