import React from 'react'

import './terminal.css';
import './fonts/Terminal.woff';
import Box from './Components/box.jsx';
import download from './Assets/download.svg'
import { useEffect } from 'react';
import YoutubeEmbed from './Components/youtube.jsx';
import Car from './Components/carousel.jsx';
import Button from './Components/button.jsx';


const Faq = ({mainBoxHeight, mainBoxWidth}) => {

	const textStyle = {color:"white", margin:0, marginBottom:"15px", fontFamily: "Terminal", fontSize:20, letterSpacing:"3px", opacity:0.9, float:"left", textAlign:"left"}


	const chatCommand = (command, desc) => {
		return <div class="row" style={{textStyle, margin:"auto"}}>
			<div class="column">
				{command}
			</div>
			<div class="column">
				{desc}
			</div>
		</div>
	}

	useEffect(() => {
		document.title = 'Killing Machine Movement - FAQ';
	}, []);

	const contents = []
	const page = []
	const heading = (heading) => {
		contents.push({"h":heading, "qs":[]})
		page.push(<><br/><h1>{heading}</h1></>)
	}
	const question = (question, children, hash) => {
		const last = contents[contents.length-1]
		if (last.qs){last.qs.push({q:question, h:hash})}
		page.push(
			<>
				
			<div class="row" style={{}}>
					<div class="column" style={{width:"90%"}}>
						<h3 id={hash}>{question}</h3>
					</div>
					<div class="column" style={{width:"10%"}}>
					<a href="#top">
					<div style={{textAlign: "center", margin:"10px", padding:"10px", border: '4px solid rgba(255, 255, 255, 0.5)', background: 'rgba(0, 0, 0, 0.5)' }}>
						▲
					</div>
				</a>
						
					</div>
				</div>
				<div style={{paddingLeft:"20px", border: '4px solid rgba(255, 255, 255, 0.5)', background: 'rgba(0, 0, 0, 0.5)' }}>
					{children}
				</div>
				<br/>
				<br/>
				<br/>
			</>
		)
	}

	heading("Installation")

	question(
		"Which file do I download?",
		<>
			<p>Only download one of the options on the <a href="/download">download page</a>. <strong>Most people should download the installer exe</strong> which will install the game and create shortcuts.</p>
			<p>Sometimes aggressive antivirus software may flag a random exe downloaded off the internet. In this case you may want to download the zip. Or you can download the zip if you're happy to extract it yourself.</p>
		</>,
		"whichDl"
	)

	question(
		"I see two exe files, which do I run?",
		<>
			<p>Once you've downloaded and installed the game, you should have two exe files Movement.exe and Movement_IRC1.exe. You'll want to run <strong>Movement.exe</strong>.</p>
			<p>Movement_IRC1.exe allows chat to work. It will run in the background if you try to connect to chat in game. If you run it by itself it will just quit immediately.</p>
		</>,
		"whichRun"
	)

	question(
		"Why is the file so big?",
		<>
			<p>There is a lot of stuff in this game. Its mostly the voice acting. There are also full books, tons of art, Signum Vitiosum's top hits.</p>
			<p>I did say it was overly ambitious.</p>
		</>,
		"bigFile"
	)

	heading("Troubleshooting")

	question(
		"The resolution is small, sounds aren't playing, my progress isn't saving - what's wrong?",
		<>
			<p>This can happen if you do not have permissions to write to the same folder as Movement is installed in.</p>
			<p>Movement writes the save file, settings and data to the same folder as it is installed to. Yes I know this isn't best practice. If you don't have permissions to write there it can cause problems.</p>
			<p>Check if there is a <i>settings.ini</i> file in the installation directory. It should exist after running the game once.</p>
			<p>If not, the easiest solution is to reinstall to a folder where you can write to. For example <i>C:/KMMovement</i> instead of <i>C:/Program Files/Movement</i>.</p>
			<p>Otherwise you can run Movement.exe as an administrator, or give your user ownership of the installation directory.</p>
		</>,
		"permission"
	)

	question("It is really laggy, what can I do?",
		<>
			<p>Sometimes the size of the screen can make the game lag on slower computers.</p>
			<p>On the main menu select <i>options</i>. <strong>Change the resolution to the minimum 1024x768</strong> to see if that helps. You may have to untick <strong>fill screen</strong>.</p>
			<p>You can also select <strong>disable extra effects</strong> which can help on some levels.</p>
		</>,
		"lag"
	)

	question("It takes the game a very long time to open, can I do anything to help?",
		<>
			<p>Unfortunately not much. It happens because there is <a href="#bigFile">so much stuff in this game</a>.</p>
			<p>Try installing on a SSD rather than a HDD if you have one.</p>
		</>,
		"openSlow"
	)

	question("Why won't the game run?",
		<>
			<p>Your antivirus software may be blocking it. Add any exceptions as required.</p>
			<p>Restarting your computer may help too.</p>
			<p>Is it just taking ages to start? It does that. See the <a href="#openSlow">previous question</a>.</p>
		</>,
		"notRunning"
	)


	heading("Chat")

	question(
		"How do I connect to chat?",
		<>
			<p>You can connect to chat either on the main menu in the chat section by clicking <strong>connect</strong>, the pause screen in the chat tab, or in game using the <strong>/connect</strong> command when you press enter.</p>
			<p>You can also join chat in the <a href="/chat">web client</a>. Or by using your favourite IRC client and joining <strong>#Movement</strong> on <strong>irc.quakenet.org</strong>.</p>
		</>,
		"howChat"
	)

	question(
		"Why can't I connect to chat?",
		<>
			<p>When you try to connect to chat the game will run a process (Movement_IRC1) in the background. Your firewall should ask you if you want to allow this. Then after a few seconds you should see the message <strong>"Connected. Now talking in #Movement"</strong>.</p>
			<p>A few things can go wrong here:
				<ul>
					<li>If your <strong>firewall</strong> blocks joining chat, you'll need to allow that.</li>
					<li>You may need to manually add a firewall rule to allow both Movement.exe and Movement_IRC1.exe outgoing and ingoing connections.</li>
					<li>If you took too long to enable your firewall before trying to connect, try disconnecting and reconnecting again. You can do this with the <strong>disconnect</strong> buttons on menu or pause screen, or with the <strong>/disconnect</strong> command in-game.</li>
					<li>Try <strong>quitting the game</strong>, waiting a few seconds (for Movement_IRC1 to exit), then reopening.</li>
					<li>Did you install/extract Movement_IRC1.exe? Check the installation directory for it. If its not present in the same folder as Movement.exe then you won't be able to join chat. Reinstall and select the chat option.</li>
					<li>If you can see messages, but not send them, it may be because multiple Movement_IRC1 instances are running. Quit them all and restart the game.</li>
					<li>You can see more detail from the IRC server on the main menu than in-game. <strong>Joining chat on the menu</strong> may give you more ideas as to why you cannot connect.</li>
					<li>Sometimes if you try to join too many times too quickly you may get throttled and have to wait a while before trying again.</li>
				</ul>
			</p>
		</>,
		"whyNotChat"
	)

	question(
		"Which chat commands are available?",
		<>
			<h4>System</h4>
			
				{chatCommand("/played","show time played")}
				{chatCommand("/version","show game version")}
			
			<h4>Chat</h4>
				{chatCommand("/connect","connect to chat")}
				{chatCommand("/disconnect","disconnect from chat")}
				{chatCommand("/who","display who is in chat")}
				{chatCommand("/name","change name")}
				{chatCommand("/me","emote")}
			
			<h4>Game</h4>
			
				{chatCommand("/notes","display notes")}
				{chatCommand("/objectives","display objectives")}
				{chatCommand("/oneliner","say a one liner")}
				{chatCommand("/kill","suicide")}
			
			<h4>Cheats</h4>
			
				{chatCommand("/sv_cheats","enable cheats, 1=on (MUST BE ENABLED IN OPTIONS)")}
				{chatCommand("/god","god mode (infinite health)")}
				{chatCommand("/archon","archon mode (infinite mana)")}
				{chatCommand("/impulse 420", "all guns")}
				{chatCommand("/insane","no weapon cooldown")}
				{chatCommand("/lolweaponz","constantly switch weapons")}
				{chatCommand("/touchedge","disable death on touch level edges")}
				{chatCommand("/bigclip","give lots of ammo in clip")}
				{chatCommand("/bigammo","give lots of ammo")}
				{chatCommand("/streaker","become naked")}
				{chatCommand("/medusa","medusa mode")}
				{chatCommand("/VADS","very aggressive defensive system")}
				{chatCommand("/aqualung","breathe underwater")}
				{chatCommand("/instagib","instagib ASMD shock rifle")}
				{chatCommand("/skul-minigun","rapid fire skul-gun")}
				{chatCommand("/notarget","enemies can't see you")}
				{chatCommand("/XOR","XOR vision")}
				{chatCommand("/fred","always explode")}
				{chatCommand("/upsidedown","flip game screen")}
				{chatCommand("/ultimatepower","all ultimate spells")}
				{chatCommand("/showresistances","show all enemy resistances/weaknesses")}
				{chatCommand("/booster","allow infinite boosts")}
				{chatCommand("/amazingdan","super powered kung fu")}
				{chatCommand("/killall","destroy everything destructible")}
				{chatCommand("/killhostile","kill all hostiles")}
				{chatCommand("/sv_recoil","change recoil value")}
				{chatCommand("/sv_timeconstant","change time constant")}
				{chatCommand("/sv_vision","change vision distance")}
				{chatCommand("/sv_gravity","change gravity")}
				{chatCommand("/sv_speed","change run speed/air control")}
				{chatCommand("/sv_jump","change jump height")}
				{chatCommand("/sv_playerangle","change player angle")}
				{chatCommand("/sv_playersize","change player size")}
				{chatCommand("/sv_explosionsize","change explosion size")}
				{chatCommand("/spawn","spawn an enemy, eg /spawn 1")}
			<br/>
		</>,
		"chatCommands"
	)

	heading("Gameplay")

	question("How do I move?",
		<>
			<p>Use <strong>WASD</strong> to move. Move the mouse to look around. Basics Tutorial will teach you this.</p>
			<p>If you are on the Tutorial just starting out, you have to use the mouse to look at the spinny guy top right of you first before you can move.</p>
		</>,
		"howMove"
	)

	question("I'm finding it hard to boost, any tips?",
		<>
			<p>Boosts are a fundamental move in Movement. Mastering them will do you well. The Basics Tutorial should help you practice.</p>
			<p>Double tap a direction when you're in mid air to boost. Press <strong>W W</strong> to get into the air, then once in the air you can use each of <strong>A A</strong>, <strong>D D</strong> and <strong>S S</strong> before you need to touch the ground again. Unless you have equipped more boosts of course.</p>
			<p>If you etherialise through floor you can boost again too.</p>
			<p>If you walk right up to a wall Kador goes into a bent knee jumping frame, which means you can't boost up. Back up from the wall a bit.</p>
		</>,
		"boost"
	)

	question("I can't shoot?",
		<>
			<p>Play the gun trutorial.</p>
			<p><strong>Aim a bit further away</strong> from yourself by moving the mouse away.</p>
		</>,
		"shoot"
	)

	question("How do I cast two spells at once using CTRL?",
		<>
			<p>Usually you cast spells using right click. But it only casts the spell for the duration the mouse is held.</p>
			<p>Sometimes it is useful to cast two spells at once. For example float and etherialise would allow you to float up through floors.</p>
			<p>To achive this, you can select one spell, <strong>hold CTRL and right click</strong>. This toggles the spell on so you don't have to hold right click down. Then <strong>you can switch spells and cast another</strong>.</p>
		</>,
		"spellCtrl"
	)

	question("This game is really hard.",
		<>
			<p>That's not a question. But yes there is definitely a learning curve. You should be prepared for a challenge - but you'll get the hang of it.</p>
			<p>If you're having trouble with the tutorials, <strong>just skip to the real game</strong> by clicking on the <strong>Mass City</strong> tab on the map screen. Parts of the tutorials aren't required, especially at the start, and you certainly don't need to complete them all before beginning.</p>
			<p>You should also <strong>set the game on easy mode</strong> which makes various things easier, including giving you more health and damage.</p>
			<p>You can select easy mode either from the initial prompt when you enter the map screen for the first time, or by clicking on the curent game mode on the left of the map screen. The game mode will say <strong>normal</strong> if you're not on easy mode, or <strong>easy</strong> if you're already on easy mode.</p>
			<p>It may also help to customise your equipment by clicking the <strong>custom equip</strong> button on map screen when you've selected a level.</p>
			<p>
				General tips:
				<ul>
					<li>Use <strong>potions</strong> a lot. The game is basically impossible without them. Default equips have hotkeyed <strong>1</strong> for health and <strong>2</strong> for mana. Potions are also always mapped to <strong>[</strong> and <strong>]</strong></li>
					<li><strong>Skip sections</strong>. You can teleport or fly past a lot of stuff. If your objective is just to get to a location you can probably skip a lot.</li>
					<li><strong>Slow time</strong> is a good spell. It makes the game a lot easier when you use it a lot.</li>
					<li><strong>Use hotkeys for spells</strong>. The most important defaults are <strong>Q</strong> for teleport, <strong>E</strong> for etherialise, <strong>Z</strong> for float and <strong>C</strong> for slow time.</li>
					<li>Occular implants <strong>vision modes</strong> let you see things that are usually invisible, like mines or trap lasers.</li>
					<li><strong>Lightning gun hitting water</strong> can kill everything in that water. Be careful though as it will instantly kill you. Lightning gun alt-fire <strong>EMP</strong> can stop trap lasers and have other useful effects.</li>
				</ul>
			</p>
		</>,
		"hard"
	)

	question("How do I see notes and objectives?",
		<>
			<p>Press <strong>ESC</strong> to pause and use the relevant tabs on the pause screen.</p>
			<p>You can also use the <strong>/notes</strong> and <strong>/objectives</strong> chat commands.</p>
		</>,
		"notesObjectives"
	)

	question("How do I turn music off / change volume?",
		<>
			<p>Press <strong>ESC</strong> to pause and click on the sound tab. Drag the sliders to change volumes for sound effects, music and speech.</p>
			<p>You can also change these volume settings in the main menu options.</p>
		</>,
		"volume"
	)

	question("I am stuck, how do I kill myself?",
		<>
			<p>Press <strong>K</strong> or type <strong>/kill</strong> in chat.</p>
		</>,
		"kill"
	)

	question("How do I use cheats?",
		<>
			<p>First you need to enable <strong>allow cheating</strong> in menu options.</p>

			<p>Then in game, type <strong>/sv_cheats 1</strong> in chat. Then you can use one of the cheat <a href="#chatCommands">chat commands</a>.</p>
		</>,
		"cheats"
	)





	

	return <Box height={mainBoxHeight-160} width={mainBoxWidth-60} down={100} style={{paddingTop:20}}>
		<div style={{...textStyle, overflowY:"scroll", height:mainBoxHeight-220}}>
			<h1 id="top">Frequently Asked Questions</h1>
			<div style={{border: '4px solid rgba(255, 255, 255, 0.5)', background: 'rgba(0, 0, 0, 0.5)' }}>
				<ul>
					{contents.map(c => {
						return <li>
							{c.h}
							<ul>
								{c.qs.map(q => <li><a href={`#${q.h}`}>{q.q}</a></li>)}
							</ul>
						</li>
					})}
				</ul>
			</div>
			<div style={{overflow:"hidden"}}>
				{page}
			</div>
		</div>
	</Box>
}

export default Faq