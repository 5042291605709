import React from "react";
import PropTypes from "prop-types";
import Box from "./box.jsx";

const YoutubeEmbed = ({ embedId, height, autoplay }) => (

    <div className="video-responsive" style={{height:height-60, border: '4px solid rgba(255, 255, 255, 0.5)'}}>
      <iframe
        height={height-60}
        src={`https://www.youtube.com/embed/${embedId}?autoplay=${autoplay?1:0}&mute=${autoplay?1:0}`}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
        title="Embedded youtube"
      />
    </div>
);

YoutubeEmbed.propTypes = {
  embedId: PropTypes.string.isRequired
};

export default YoutubeEmbed;