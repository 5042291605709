import React from 'react'

import Box from './Components/box.jsx';
import { useEffect } from 'react';

const Chat = ({mainBoxHeight, mainBoxWidth}) => {

	useEffect(() => {
		document.title = 'Killing Machine Movement - Chat';
	}, []);

	return <Box height={mainBoxHeight-160} width={mainBoxWidth-60} down={100} style={{paddingTop:20}}>
		<iframe src="https://webchat.quakenet.org/?channels=movement&uio=MTE9MA4c" style={{width:"100%", height:`${mainBoxHeight-220}px`, border:0}}/>
	</Box>
}

export default Chat