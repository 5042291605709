import './App.css';
import Background from './Components/background';
import Logo from './Components/logo';
import Box from './Components/box';
import About from './About.jsx';
import Download from './Download.jsx';
import Chat from './Chat.jsx';
import History from './History.jsx';
import Faq from './Faq.jsx';
import ButtonBar from './Components/buttonBar';
import blood from './Assets/blood.png'
import React, { useState } from 'react';
import { Routes, Route } from 'react-router-dom';


function App() {
  const [windowX, setWindowX] = useState(window.innerWidth);
  const [windowY, setWindowY] = useState(window.innerHeight);

  console.log(windowY)

  
  if (windowY > 1260) {
    document.body.style.overflowY = "hidden"
  } else {
    document.body.style.overflowY = "scroll"
  }
  if (windowX > 800) {
    document.body.style.overflowX = "hidden"
  } else {
    document.body.style.overflowX = "scroll"
  }

  React.useEffect(() => {
    const handleResize = () => {
      setWindowX(window.innerWidth)
      setWindowY(window.innerHeight)
    }

    window.addEventListener('resize', handleResize)
  })

  const borderWidth = Math.max(0,Math.min(200, windowX - 1000))

  const mainBoxWidth = Math.min(1700,Math.max(800, windowX-borderWidth))
  const mainBoxHeight = Math.max(1000, windowY-260)



  return (
    <div className="App">
      <div style={{position: 'absolute', overflow:'hidden', zIndex: "-1"}}>
        <Background screenX={Math.max(windowX,mainBoxWidth+borderWidth)} screenY={mainBoxHeight+260}/>
      </div>
      <img src={blood} alt={"blood"} style={{display:"block", marginLeft:"auto", marginRight:"auto", opacity:0.6, maxWidth: mainBoxWidth}}/>
      <div>
        <Logo/>
        <Box height={mainBoxHeight} width={mainBoxWidth} corner={50} style={{position:"absolute", top:220, left:Math.max(0,(windowX-mainBoxWidth)/2), margin:"0 auto"}}>
        
          <ButtonBar width={mainBoxWidth-60} height={80} buttons={[
            {text: "About", href:"/"},
            {text: "Download", href:"/download", minWidth:190},
            {text: "Chat", href:"/chat"},
            {text: "FAQ", href:"/faq"},
            {text: "History", href:"/history", minWidth:180}
          ]} />
          <Routes>
            <Route path="/download" element={
              <Download mainBoxHeight={mainBoxHeight} mainBoxWidth={mainBoxWidth}/>
            } />
            <Route path="/chat" element={
              <Chat mainBoxHeight={mainBoxHeight} mainBoxWidth={mainBoxWidth}/>
            } />
            <Route path="/history" element={
              <History mainBoxHeight={mainBoxHeight} mainBoxWidth={mainBoxWidth}/>
            } />
            <Route path="/faq" element={
              <Faq mainBoxHeight={mainBoxHeight} mainBoxWidth={mainBoxWidth}/>
            } />
            <Route path="*" element={
              <About mainBoxHeight={mainBoxHeight} mainBoxWidth={mainBoxWidth}/>
            } />
          </Routes>
        </Box>
      </div>

        


      
    </div>
  );
}

export default App;
